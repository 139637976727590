.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100vh;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 350px;
}
