.sidebar {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: thin;
    align-items: center;
}

.sidebar-user {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f5f5f5;
}

.sidebar-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}