@use "sass:map";
@use "colors";

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: map-get(colors.$colors, "background");
  color: map-get(colors.$colors, "text");
}

.app-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.app-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.app-sidebar {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 16px;
  width: 218px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.app-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 16px;
}

.app-footer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}
